import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9cb3543d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  class: "dropdown",
  ref: "dropdown_ref"
}
const _hoisted_4 = { class: "dropdown-options" }
const _hoisted_5 = {
  key: 0,
  class: "group-title"
}
const _hoisted_6 = ["title"]
const _hoisted_7 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("button", {
    ref: "dropdown_btn_ref",
    class: "dropdown-btn btn btn-xs btn-icon",
    onClick: _withModifiers($setup.toggle_fieldtype_options, ["stop"])
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createElementVNode("div", {
        innerHTML: _ctx.frappe.utils.icon('dot-horizontal', 'sm')
      }, null, 8 /* PROPS */, _hoisted_2)
    ], true),
    (_openBlock(), _createBlock(_Teleport, { to: "#autocomplete-area" }, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.groups, (group) => {
            return (_openBlock(), _createElementBlock("div", {
              key: group.key,
              class: "groups"
            }, [
              (group.group)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(group.group), 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.items, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "dropdown-option",
                  key: item.label,
                  title: item.tooltip
                }, [
                  _createElementVNode("button", {
                    class: "dropdown-item",
                    onClick: _withModifiers($event => ($setup.action(item.onClick)), ["stop"])
                  }, _toDisplayString(item.label), 9 /* TEXT, PROPS */, _hoisted_7)
                ], 8 /* PROPS */, _hoisted_6))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ], 512 /* NEED_PATCH */), [
          [_vShow, $setup.show]
        ])
      ], 512 /* NEED_PATCH */)
    ]))
  ], 8 /* PROPS */, _hoisted_1))
}